<template>
  <div>
    <base-table :data="data" thead-classes="text-primary">
      <template slot="columns">
        <th>Id</th>
        <th>Nome</th>
        <th>Motorista</th>
        <th>Telefone</th>
        <th>Status</th>
        <th class="text-right">Opções</th>
      </template>

      <template slot-scope="{ row }">
        <td>{{ row.id }}</td>
        <td>{{ row.name }}</td>
        <td>{{ row.client && row.client.driver && row.client.driver.user && row.client.driver.user.name ? row.client.driver.user.name : 'Motorista não vinculado' }}</td>
        <td>{{ row.cell_phone }}</td>
        <td>
          <span v-if="row.client && row.client.status === 1" style="color: green;">Ativo</span>
          <span v-if="row.client && row.client.status === 0" style="color: red;">Inativo</span>
          <span v-if="row.client && row.client.status === -1" style="color: red;">Deletado</span>
        </td>
        <td class="text-right">
          <el-tooltip
            content="Editar"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <router-link :to="`clients/new/${row.id}`">
              <base-button type="info" icon size="sm" class="btn-link">
                <i class="tim-icons icon-pencil"></i>
              </base-button>
            </router-link>
          </el-tooltip>

          <!-- <el-tooltip
            v-if="row.is_active !== -1"
            content="Remover"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              type="danger"
              icon
              size="sm"
              class="btn-link"
              @click="removeOrActiveClient(row)"
            >
              <i class="tim-icons icon-trash-simple"></i>
            </base-button>
          </el-tooltip> -->
          <!-- <el-tooltip
            v-else
            content="Ativar"
            effect="light"
            :open-delay="300"
            placement="top"
          >
            <base-button
              type="success"
              icon
              size="sm"
              class="btn-link"
              @click="removeOrActiveClient(row)"
            >
              <i class="tim-icons icon-check-2"></i>
            </base-button>
          </el-tooltip> -->
        </td>
      </template>
    </base-table>
  </div>
</template>
<script>
import { BaseTable } from '@/components'

export default {
  props: ['data', 'removeIndex'],
  methods: {
    async removeOrActiveClient(client) {
      await this.$http.delete(`/clients/${client.id}`)
      this.removeIndex(client.id)
    }
  },
  components: { BaseTable }
}
</script>
