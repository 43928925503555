<template>
  <div class="row">
    <div class="col-12">
      <h2>Clientes</h2>
      <Card>
        <div class="row">
          <div class="col-md-8">
            <base-dropdown
              title-classes="btn btn-warning btn-block"
              titleTag="div"
              style="width: 15rem"
              :title="filterTitle"
            >
              <a
                v-for="status in allStatus"
                :key="status.id"
                class="dropdown-item"
                href="#"
                @click="clientStatus = status.id"
              >
                {{ status.name }}
              </a>
            </base-dropdown>
          </div>
          <div
            class="col-md-4 form-row d-flex justify-content-sm-end flex-wrap"
          >
            <router-link to="/clients/new">
              <base-button type="warning add-button">
                Adicionar
              </base-button>
            </router-link>
            <base-input
              type="text"
              v-model="search"
              placeholder="Busca"
              @keyup.enter.native="handleSearch"
            />
          </div>
        </div>
        <ClientTable :data="clients.data" :removeIndex="removeIndex" />
        <div class="pagination-div">
          <base-pagination
            type="warning"
            :page-count="clients.last_page"
            v-model="current_page"
            @input="handleChangePage"
          />
        </div>
      </Card>
    </div>
  </div>
</template>
<script>
import Card from '@/components/Cards/Card'
import { BasePagination, BaseInput } from '@/components'
import ClientTable from './ClientTable.vue'

export default {
  name: 'ClientPage',
  async created() {
    await this.makePagination(this.current_page)
  },
  data: () => ({
    clients: false,
    current_page: 1,
    search: '',
    clientStatus: 0,
    allStatus: [
      { name: 'ativos', id: 0 },
      { name: 'deletados', id: -1 }
    ]
  }),
  methods: {
    handleSearch() {
      let search = null
      if (this.search) {
        search = this.search
      }

      // reset search
      this.makePagination(1, search)
    },
    handleChangePage(next_page) {
      this.makePagination(next_page)
    },
    async makePagination(page, search = null) {
      let url = `/clients?page=${page}`

      if (search) {
        url += `&search=${search}`
      }

      if (this.clientStatus !== 0) {
        url += '&deleted=1'
      }

      const {
        data: { data }
      } = await this.$http.get(url)
      this.clients = data
      this.current_page = data.current_page
    },
    removeIndex(id) {
      const clientIndex = this.clients.data.findIndex(d => d.id === id)
      this.clients.data.splice(clientIndex, 1)
    }
  },
  components: { Card, BasePagination, BaseInput, ClientTable },
  watch: {
    current_page() {
      setTimeout(() => {
        window.scrollTo(0, 0)
      }, 800)
    },
    clientStatus() {
      this.current_page = 1
      this.makePagination(1)
    }
  },
  computed: {
    filterTitle() {
      const prefix = 'Clientes '
      const [status] = this.allStatus.filter(i => i.id === this.clientStatus)
      return `${prefix}${status.name}`
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-div {
  display: flex;
  justify-content: flex-end;
}

.add-button {
  height: 40px;
  margin: 0;
  margin-right: 15px;
}
</style>
